import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 124px;
  padding: 32px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;

  img {
    max-width: 160px;
    max-height: 80px;
    padding-bottom: 8px;

    @media only screen and (max-width: 460px) {
      max-width: 120px;
      max-height: 32px;
    }
  }

  @media only screen and (max-width: 460px) {
    padding: 16px;
    height: 132px;
    flex-direction: column-reverse;
    margin-bottom: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Contratante = styled.h2`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media only screen and (max-width: 460px) {
    font-size: 16px;
  }
`;

export const SurveyTitle = styled.h3`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 460px) {
    font-size: 12px;
  }
`;
