import React from 'react';
import { ReactComponent as LupaSVG } from '../../assets/images/magnifying-glass.svg';

const Lupa: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <LupaSVG className={className} />;
};

export { Lupa };
