import styled, { css } from 'styled-components';

interface ICheckButton {
  checked: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NumericScaleContainer = styled.div<{ itemCount: number }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  width: 100%;
  max-width: ${({ itemCount }) => `calc(${itemCount} * (48px + 8px) - 8px)`};

  @media only screen and (min-width: 460px) {
    max-width: ${({ itemCount }) => `calc(${itemCount} * (64px + 8px) - 8px)`};
  }
`;

export const NumericScaleWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;

  &.special {
    display: flex;
    justify-content: center;
    width: auto;
    gap: 8px;
  }
`;

export const Item = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.gray500};
  white-space: nowrap;
`;

export const CheckButton = styled.div<ICheckButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  max-height: 48px;
  min-width: 48px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray150};

  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
    p {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondary};
    p {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${props =>
    props.checked &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      p {
        color: ${({ theme }) => theme.colors.white};
      }
  `}

  @media only screen and (min-width: 460px) {
    min-height: 56px;
    max-height: 56px;
    min-width: 64px;
  }
`;
