import { Arrow } from 'components/Icons/Arrow';
import { Lupa } from 'components/Icons/Lupa';
import { Alternativa, Pergunta, TipoAlternativa } from 'core/pesquisa/Pesquisa';
import {
  AlternativaAbertaNumeroSelecionada,
  AlternativaAbertaTextoSelecionada,
  AlternativaSelecionada,
} from 'core/pesquisa/resposta/pergunta/aternativa/RespostaAlternativa';
import CoreContext from 'providers/core';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { AlternativaFechada } from 'sections/Question/Alternativas/AlternativaFechada';
import { AlternativaAberta } from '../Alternativas/AlternativaAberta';
import { PerguntaRef, genericRespostaAtual } from '../interfaces';
import {
  Select,
  SelectContainer,
  SelectOptions,
  SelectOptionsInput,
  SelectOptionsList,
  SelectSpan,
  SelectWrapper,
} from './styles';

interface Props {
  pergunta: Pergunta;
  idSecao: number;
  className: string;
  respostaAtual: (id: number) => genericRespostaAtual;
}

const PerguntaSimplesReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const { pergunta, idSecao, className, respostaAtual } = props;
  const questionState = {
    ...pergunta,
    alternativas: pergunta.alternativas ? pergunta.alternativas : [],
  };

  const [alternativaSelecionada, setAlternativaSelecionada] =
    useState<AlternativaSelecionada>();
  const [idDestinoPulo, setIdDestinoPulo] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAlternatives, setFilteredAlternatives] = useState([
    ...questionState.alternativas,
  ]);

  const { errosValidationQuestions } = useContext(CoreContext);

  /**
   * Obtem o valor digitado pelo usuario no campo de busca do seletor
   * Atualizada filteredAlternatives com base no valor digitado
   */
  useEffect(() => {
    const alts = questionState.alternativas.filter(alternativa =>
      alternativa.enunciado.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredAlternatives(alts);
  }, [questionState.alternativas, searchTerm]);

  useImperativeHandle(ref, () => ({
    alternativas: alternativaSelecionada ? [alternativaSelecionada] : [],
    data: null,
    horario: null,
    texto: null,
    foto: null,
    alternativas_selecionadas: null,
    destinoPulo: idDestinoPulo || null,
    vazia: alternativaSelecionada === undefined,
  }));

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);
    if (respostaLocalAtual) {
      const { estadoAtual, idPerguntaDestinoPulo } = respostaLocalAtual;
      if (idPerguntaDestinoPulo > 0) {
        setIdDestinoPulo(idPerguntaDestinoPulo);
      }
      setAlternativaSelecionada(estadoAtual[0]);
    }
  }, [pergunta.id, respostaAtual]);

  const buildRespostaAlternativa = useCallback(
    (selectedOptionId: number) => {
      const alternativaAtual = questionState.alternativas.find(
        alt => alt.id === selectedOptionId,
      );
      if (alternativaAtual) {
        if (alternativaAtual.marcacao?.id_pergunta_destino_pulo) {
          setIdDestinoPulo(alternativaAtual.marcacao?.id_pergunta_destino_pulo);
        } else {
          setIdDestinoPulo(0);
        }
        return {
          id_alternativa: alternativaAtual.id,
          ordem_selecao: alternativaAtual.ordem,
          tipo_alternativa: alternativaAtual.tipo_alternativa,
          comportamento: alternativaAtual.comportamento,
        };
      }
      return null;
    },
    [questionState.alternativas],
  );
  const onChangeCallback = useCallback(
    evt => {
      const selOptId = Number(evt.value);
      const altBuild = buildRespostaAlternativa(selOptId);
      setIsOpen(false);

      if (alternativaSelecionada?.id_alternativa === altBuild?.id_alternativa) {
        setAlternativaSelecionada(undefined);
        setIdDestinoPulo(0);
        return null;
      }
      if (altBuild) {
        setAlternativaSelecionada(altBuild);
      }

      return null;
    },
    [buildRespostaAlternativa, alternativaSelecionada],
  );

  const onChangeTextoAberta = (
    value: string,
    alternativa: AlternativaSelecionada,
  ) => {
    if (alternativa.tipo_alternativa === TipoAlternativa.ABERTA_TEXTO) {
      setAlternativaSelecionada({
        ...alternativa,
        texto: value,
      } as AlternativaAbertaTextoSelecionada);
    } else {
      setAlternativaSelecionada({
        ...alternativa,
        numero: Number(value),
      } as AlternativaAbertaNumeroSelecionada);
    }
  };
  const renderAlternativa = (alternativa: Alternativa) => {
    if (alternativa.tipo_alternativa === TipoAlternativa.FECHADA) {
      return (
        <div key={`${idSecao}-${alternativa.id}`} className="">
          <AlternativaFechada
            key={`${idSecao}-${alternativa.id}`}
            alternativa={alternativa}
            onChange={onChangeCallback}
            checked={alternativaSelecionada?.id_alternativa === alternativa.id}
            tipoPergunta={pergunta.tipo_pergunta}
          />
        </div>
      );
    }
    if (
      alternativa.tipo_alternativa === TipoAlternativa.ABERTA_TEXTO ||
      alternativa.tipo_alternativa === TipoAlternativa.ABERTA_NUMERO
    ) {
      const respostaLocalAtual = respostaAtual(pergunta.id);
      const respostaAlternativaAberta =
        respostaLocalAtual && respostaLocalAtual.estadoAtual;

      const respostaAlternativaAbertaPorTipo =
        respostaAlternativaAberta &&
        alternativa.tipo_alternativa === 'ABERTA_NUMERO'
          ? (respostaAlternativaAberta &&
              respostaAlternativaAberta[0] &&
              respostaAlternativaAberta[0].numero) ||
            ''
          : (respostaAlternativaAberta &&
              respostaAlternativaAberta[0] &&
              respostaAlternativaAberta[0].texto) ||
            '';

      return (
        <div className="" key={`${idSecao}-${alternativa.id}`}>
          <AlternativaAberta
            key={`${idSecao}-${alternativa.id}`}
            alternativa={alternativa}
            onChange={onChangeCallback}
            onChangeTexto={onChangeTextoAberta}
            checked={alternativaSelecionada?.id_alternativa === alternativa.id}
            abertaNumero={
              alternativa.tipo_alternativa === TipoAlternativa.ABERTA_NUMERO
            }
            tipoPergunta={pergunta.tipo_pergunta}
            valorAnterior={respostaAlternativaAbertaPorTipo}
            radio
            errors={
              questionState.alternativas.length === 1
                ? errosValidationQuestions
                : undefined
            }
          />
        </div>
      );
    }
    return null;
  };
  return (
    <>
      {questionState.alternativas.every(
        a => a.tipo_alternativa === TipoAlternativa.FECHADA,
      ) && questionState.alternativas.length >= 10 ? (
        <SelectContainer>
          <Select isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
            <SelectSpan>
              {alternativaSelecionada && !isOpen
              ? questionState.alternativas.find(
                  a => a.id === alternativaSelecionada?.id_alternativa,
                )?.enunciado
              : 'Selecione uma alternativa...'}
            </SelectSpan>
            <Arrow className='arrow' />
          </Select>
          {isOpen && (
            <SelectOptions isOpen={isOpen}>
              <SelectOptionsInput>
                <input
                  type="text"
                  placeholder="Buscar..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
                <Lupa className='magnifying-glass' />
              </SelectOptionsInput>
              <SelectWrapper>
                <SelectOptionsList>
                  {filteredAlternatives.map(alternativa => (
                    <AlternativaFechada
                      key={`${idSecao}-${alternativa.id}`}
                      alternativa={alternativa}
                      onChange={onChangeCallback}
                      checked={
                        alternativaSelecionada?.id_alternativa ===
                        alternativa.id
                      }
                      tipoPergunta={pergunta.tipo_pergunta}
                      isSelectRender
                    />
                  ))}
                </SelectOptionsList>
              </SelectWrapper>
            </SelectOptions>
          )}
        </SelectContainer>
      ) : (
        <div className={`${className} flex flex-col`}>
          <div className="flex flex-col gap-y-6">
            {questionState.alternativas.map(alternativa =>
              renderAlternativa(alternativa),
            )}
          </div>
        </div>
      )}
    </>
  );
};

const PerguntaSimples = forwardRef(PerguntaSimplesReferenciable);
export { PerguntaSimples };
