import styled from 'styled-components';

export const UiFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 24px 0 16px 0;
  .tensai-logo {  
    height: 34px;
  }
`;

export const FooterText = styled.span`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
