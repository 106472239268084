import styled, { css } from 'styled-components';

interface ICheckButton {
  checked: boolean;
  rounded: string;
}

interface ISelectRender {
  isSelectRender: boolean;
}

export const Container = styled.div<ISelectRender>`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  
  @media only screen and (min-width: 460px) {
    min-height: ${({ isSelectRender }) => isSelectRender ? '20px' : '40px'};
  }
`;

export const CheckButton = styled.div<ICheckButton>`
  min-height: 16px;
  min-width: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: ${({ rounded }) => rounded};

  .selected-icon {
    display: ${({ checked }) => checked ? 'block' : 'none'};
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
  
  ${props =>
    props.checked &&
    css`
      border: none;
    `}

  @media only screen and (min-width: 460px) {
    min-height: 24px;
    min-width: 24px;
  }

`;

export const Label = styled.label<ISelectRender>`
  color: ${({ theme }) => theme.colors.gray500};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;


  @media only screen and (min-width: 460px) {
    font-size: ${({ isSelectRender }) => isSelectRender ? '14px' : '16px'};
  }
`;
