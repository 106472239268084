import { Alternativa, Pergunta, TipoAlternativa } from 'core/pesquisa/Pesquisa';
import { AlternativaSelecionada } from 'core/pesquisa/resposta/pergunta/aternativa/RespostaAlternativa';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { PerguntaRef, genericRespostaAtual } from '../interfaces';
import { CheckButton, Container, Item, NumericScaleContainer, NumericScaleWrapper } from './styles';

interface Props {
  pergunta: Pergunta;
  respostaAtual: (id: number) => genericRespostaAtual;
}

class MetaAlternativa {
  static readonly NAO_SEI = 'Não sei';
  static readonly NAO_SE_APLICA = 'Não se aplica';

  private static readonly VALORES = new Set([MetaAlternativa.NAO_SEI, MetaAlternativa.NAO_SE_APLICA]);

  static isInstanceOf(enunciado: string): boolean {
    return MetaAlternativa.VALORES.has(enunciado);
  }
}

const PerguntaEscalaNumericaReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const { pergunta, respostaAtual } = props;
  const [sortedAlternatives, SetSortedAlternatives] = useState<Alternativa[]>([]);
  const [value, setValue] = useState<number | string>('');
  const [alternativaSelecionada, setAlternativaSelecionada] =
    useState<AlternativaSelecionada | ''>({} as AlternativaSelecionada);
  const [idDestinoPulo, setIdDestinoPulo] = useState(0);

  /**
   * Função responsável por mesclar os dois arrays retornados pelo back-end
   * Alternativas: corresponde as alternativas daquela pergunta
   * Meta_alternativas: diz respeito as alternativas "Não sei / Não se aplica"
   * 
   * Após a mescla o array é ordenado para devida renderização
   */
  useEffect(() => {
    if (pergunta) {
      const buildAlternativeList = () => {
        const combinedAlternatives = (pergunta.alternativas ?? []).concat(pergunta.meta_alternativas ?? []);
        const alternatives = combinedAlternatives
          .sort((a, b) => a.ordem - b.ordem)
          .map((alt) => alt);
        SetSortedAlternatives(alternatives);
      };

      buildAlternativeList();
    }
  }, [pergunta]);

  useEffect(() => {
    const altEncontrada = sortedAlternatives?.find(
      alt => alt.enunciado === value,
    );

    const idPerguntaDestinoPulo =
      altEncontrada?.marcacao?.id_pergunta_destino_pulo;
    if (idPerguntaDestinoPulo && idPerguntaDestinoPulo > 0) {
      setIdDestinoPulo(idPerguntaDestinoPulo);
    }

    if (altEncontrada) {
      setAlternativaSelecionada({
        id_alternativa: altEncontrada?.id || 0,
        ordem_selecao: 1,
        tipo_alternativa: TipoAlternativa.FECHADA,
        comportamento: altEncontrada?.comportamento,
      });
    } else {
      setAlternativaSelecionada('');
    }
  }, [sortedAlternatives, value]);

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);

    if (respostaLocalAtual && respostaLocalAtual.estadoAtual) {
      const altEncontrada = sortedAlternatives?.find(
        alter => alter.enunciado === respostaLocalAtual.estadoAtual,
      );

      setValue(respostaLocalAtual.estadoAtual);

      const novaAlternativa = {
        id_alternativa: altEncontrada?.id || respostaLocalAtual.estadoAtual,
        ordem_selecao: 1,
        tipo_alternativa: TipoAlternativa.FECHADA,
        comportamento: altEncontrada?.comportamento,
      };

      setAlternativaSelecionada(novaAlternativa);
    }
  }, [sortedAlternatives, pergunta.id, respostaAtual]);

  useImperativeHandle(ref, () => {
    return {
      alternativas: alternativaSelecionada ? [alternativaSelecionada] : [],
      data: null,
      horario: null,
      foto: null,
      alternativas_selecionadas: null,
      texto: null,
      destinoPulo:
      alternativaSelecionada && alternativaSelecionada.comportamento === 'PULAR_PARA_PERGUNTA'
          ? idDestinoPulo
          : null,
      vazia: alternativaSelecionada === '',
      value,
    };
  });

  const handleChange = (alternative: Alternativa) => {
    setValue(alternative.enunciado);
    setAlternativaSelecionada({
      id_alternativa: alternative.id,
      ordem_selecao: 1,
      tipo_alternativa: TipoAlternativa.FECHADA,
      comportamento: alternative.comportamento,
    });
  };

  return (
    <Container>
      <NumericScaleContainer itemCount={pergunta.alternativas?.length || 0}>
        {sortedAlternatives.map((alternative) => {
          return (
            <NumericScaleWrapper 
              key={alternative.id} 
              className={MetaAlternativa.isInstanceOf(alternative.enunciado) ? 'special' : ''}
            >
              <CheckButton
                checked={alternative.enunciado === value}
                role="button"
                onClick={() => handleChange(alternative)}
              >
                <Item>
                  {alternative.enunciado}
                </Item>
              </CheckButton>
            </NumericScaleWrapper>
            
          );
        })}
      </NumericScaleContainer>
    </Container>
  );
};

const PerguntaEscalaNumerica = forwardRef(PerguntaEscalaNumericaReferenciable);
export { PerguntaEscalaNumerica };
