import styled from 'styled-components';

interface IOpen {
  isOpen: boolean;
}

export const SelectContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  max-width: 600px;

  @media only screen and (max-width: 460px) {
    height: 350px;
  }
`;

export const Select = styled.div<IOpen>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-bottom: ${({ isOpen }) => isOpen ? 'none' : null};
  border-radius: 4px;
  font-size: 14px;
  height: 48px;
  padding: 8px 16px;
  cursor: pointer;

  .arrow {
    transform: ${({ isOpen }) => isOpen ? 'none' : 'rotate(180deg)'};
    transition: transform 0.2s ease-in-out;
    path {
      fill: ${({ theme }) => theme.colors.gray400};
    }
    stroke: ${({ theme }) => theme.colors.gray400};
  }
`;

export const SelectSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SelectOptions = styled.div<IOpen>`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-top: ${({ isOpen }) => isOpen ? 'none' : null};
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  margin-top: -16px;
  padding: 16px;
  overflow: hidden;

  @media only screen and (max-width: 460px) {
    height: 350px;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: fit-content;
  max-height: 166px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    margin: 6px 0 6px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.scrollBar};
  }

  @media only screen and (max-width: 460px) {
    max-height: 220px;
  }
`;

export const SelectOptionsInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto 16px auto;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  input {
    border: none;
    outline: none;
    padding: 10px 36px 10px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${({ theme }) => theme.colors.gray500};
    background: transparent;

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray300};
    }
  }

  .magnifying-glass {
    position: absolute;
    right: 12px;
  }
`;

export const SelectOptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
