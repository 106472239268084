import React from 'react';
import { ReactComponent as ArrowSVG } from '../../assets/images/icon-arrow.svg';

const Arrow: React.FC<{ className: string }> = ({
  className,
}: {
  className: string;
}) => {
  return <ArrowSVG width="12.25px" height="7px" className={className} />;
};

export { Arrow };
