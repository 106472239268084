import { FooterText, UiFooter } from './styles';

interface Props {
  className?: string;
}

export const Footer = ({
  className
}: Props) => {
  return (
    <UiFooter className={className}>
      <FooterText>
        Distribuído por © 2024 Tensai. Todos os direitos reservados.
      </FooterText>
    </UiFooter>
  );
};
