import { CircleDot, SquareCheck } from 'components/Icons';
import { Alternativa, TipoPergunta } from 'core/pesquisa/Pesquisa';
import { CheckButton, Container, Label } from './styles';

interface IAlternativaFechada {
  alternativa: Alternativa;
  onChange: (checked: boolean) => void;
  checked: boolean;
  tipoPergunta: TipoPergunta;
  isSelectRender?: boolean;
}

const AlternativaFechada = ({
  alternativa,
  onChange,
  checked,
  tipoPergunta,
  isSelectRender = false
}: IAlternativaFechada) => {
  const handleChange = ev => {
    if (onChange) onChange({ ...ev, checked: !checked, value: alternativa.id });
  };
  const checktype = {
    [TipoPergunta.MULTIPLA]: '4px',
    [TipoPergunta.UNICA]: '50%',
  };

  const renderCheckIcon = () => {
    if (checktype[tipoPergunta] === '4px') {
      return <SquareCheck className='selected-icon' />;
    }
    return <CircleDot className='selected-icon' />;
  };

  return (
    <Container onClick={e => handleChange(e)} isSelectRender={isSelectRender}>
      <CheckButton
        rounded={checktype[tipoPergunta]}
        checked={checked}
        role="button"
        aria-hidden="true"
      >
        { renderCheckIcon() }
      </CheckButton>
      <Label isSelectRender={isSelectRender} htmlFor={`${alternativa.id}`}>{alternativa.enunciado}</Label>
    </Container>
  );
};

export { AlternativaFechada };
